export default [
  {
    header: 'Trang chủ',
  },
  {
    title: 'Quản lý đơn hàng',
    route: 'order-management',
    icon: 'BriefcaseIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
]

export default [
  {
    header: 'Quản lý khách hàng',
  },
  {
    title: 'Quản lý tài khoản',
    route: 'merchant-management-account',
    icon: 'UsersIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Biến động số dư',
    route: 'merchant-balance-change',
    icon: 'FileTextIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Quản lý rút tiền',
    route: 'withdrawal-management',
    icon: 'CreditCardIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
]

export default [
  {
    header: 'Cổng thanh toán payin',
  },
  {
    title: 'QR Payment',
    icon: 'ColumnsIcon',
    children: [
      {
        title: 'Quản lý ngân hàng',
        route: 'pay-in-bank-account',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Lịch sử ngân hàng',
        route: 'pay-in-bank-history',
        action: 'read',
        resource: 'ACL',
      },
    ],
  },
  {
    title: 'Lịch sử payin',
    route: 'payin-history',
    icon: 'SaveIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
]

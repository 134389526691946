export default [
  {
    header: 'Kênh USDT',
  },
  {
    title: 'Lịch sử giao dịch',
    route: 'usdt-history',
    icon: 'SaveIcon',
  },
]
